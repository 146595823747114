if (process.env.NODE_ENV === 'development') {
  window.use_production_script = typeof process.env.USE_PRODUCTION_SCRIPT === 'undefined' || process.env.USE_PRODUCTION_SCRIPT === 'true';
}

export function onDOMContentLoaded<T extends Function>(cb: T) {
  window.addEventListener('DOMContentLoaded', () => {
    if (process.env.NODE_ENV === 'production' && typeof window.use_production_script !== 'undefined') return;
    if (process.env.NODE_ENV === 'development' && window.use_production_script) return;

    if (process.env.NODE_ENV === 'development') {
      console.log('🌹 Local Script');
    }

    if (process.env.NODE_ENV === 'production') {
      console.log('🌹 Production Script');
    }

    cb();
  });
}

import { SELECTORS } from '../constants';
import type Player from '@vimeo/player';
import type { Options } from '@vimeo/player';
import { isTouchDevice } from '../utils/platformDetector';
import type { Maybe } from '../types';

export function VimeoPlayers() {
  const elements = {
    vimeo: document.querySelectorAll(SELECTORS.vimeo) as NodeListOf<HTMLIFrameElement>,
  };

  const state = {
    // players: [] as Array<{ el: HTMLDivElement; player: Player; fullPlayer: Player | null }>,
  };

  async function getVideoAspect(player: Player) {
    return (await player.getVideoHeight()) / (await player.getVideoWidth());
  }

  async function initPlayer(playerEl: HTMLDivElement) {
    const id = playerEl.getAttribute('id');
    const fullVideoId = playerEl.getAttribute('full-video-id');
    const fullPlayerEl = playerEl.cloneNode() as HTMLElement;
    let fullPlayer: Maybe<Player> = null;

    if (!id) return {};
    const options: Options = {
      id: Number(id),
      muted: true,
      loop: true,
      controls: false, // remove all controls
      autopause: false, // important for video to play in view, there are situations when next video gets visible when the previous is still in view.
    };

    const player = new window.Vimeo.Player(playerEl, options);

    if (fullVideoId) {
      const options: Options = {
        id: Number(fullVideoId),
        muted: false,
        loop: false,
        controls: true,
      };

      playerEl.parentElement?.append(fullPlayerEl);
      fullPlayer = new window.Vimeo.Player(fullPlayerEl, options);
      fullPlayerEl.style.display = 'none';
    }

    // state.players.push({ el, player, fullPlayer: fullPlayer });
    const pt = (await getVideoAspect(player)) * 100 + '%';
    playerEl.style.paddingTop = pt;
    fullPlayerEl.style.paddingTop = pt;

    return { player, playerEl, fullPlayer, fullPlayerEl };
  }

  function styles() {
    const style = document.createElement('style');
    style.innerHTML = `
      .vimeo-film {
        height: auto !important;
      }
      
      .vimeo {
        padding-top: 42.5%;
      }
      
      .vimeo iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      .vimeo.vimeo--accessible iframe {
        pointer-events: auto;
      }
    `;

    document.body.prepend(style);
  }

  function playVideo(player: Player) {
    player.play();
  }

  function pauseVideo(player: Player) {
    player.pause();
  }

  function hideElement(el: Maybe<HTMLElement>) {
    if (el) {
      el.style.opacity = '0';
      el.style.transition = '.3s ease-in';
      el.style.pointerEvents = 'none';
    }
  }
  function showElement(el: Maybe<HTMLElement>) {
    if (el) {
      el.style.opacity = '';
      el.style.transition = '.3s ease-out';
      el.style.pointerEvents = '';
    }
  }

  function handleUIVisibility({ player, playerEl, uiEl }: { player: Player; playerEl: HTMLElement; uiEl: Maybe<HTMLElement> }) {
    if (!uiEl) return;

    // Mousemove doesnt work over iframe
    playerEl.addEventListener('mouseout', async () => {
      if (await player.getPaused()) {
        showElement(uiEl);
      }
    });

    playerEl.addEventListener('mouseover', () => {
      hideElement(uiEl);
    });
  }

  // Run >>>
  styles();

  elements.vimeo.forEach(async (el) => {
    const { player, fullPlayer, playerEl, fullPlayerEl } = await initPlayer(el);
    const videoWrapper = $(el).closest('.video-wrp')[0];
    const videoContWrapper = videoWrapper.querySelector('.video-cont-wrp') as Maybe<HTMLElement>;
    const playFullVideoBtn = videoWrapper.querySelector('.video-full-btn') as Maybe<HTMLElement>;
    let disableShortVideoLogic = false;

    if (player) {
      player.setVolume(0);

      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (disableShortVideoLogic) return;

        entries.forEach((item) => {
          if (item.isIntersecting) {
            player.play();
          } else {
            player.pause();
          }
        });
      });
      observer.observe(el);

      if (playFullVideoBtn) {
        playFullVideoBtn.addEventListener('click', () => {
          if (fullPlayer) {
            disableShortVideoLogic = true;
            hideElement(playFullVideoBtn);
            hideElement(videoContWrapper);
            playerEl.style.display = 'none';
            fullPlayerEl.style.display = '';
            fullPlayerEl.classList.add('vimeo--accessible');
            fullPlayer.play();
            handleUIVisibility({ player: fullPlayer, playerEl: fullPlayerEl, uiEl: videoContWrapper });
          }
        });
      }
    }
  });
}
